import React from 'react';

import { Link  } from 'react-scroll';

interface ITopNavProps {
  readonly handleMenu: Function;
  readonly isSticky: boolean;
  readonly shouldDisappear: boolean;
}

export const TopNav: React.FC<ITopNavProps> = (props) => {
  const handleSetActive = (value: any) => {
    if (value === 'banner') {
      props.handleMenu(false);
    }
    if (value === 'abonnements') {
      props.handleMenu(true);
    }
  }

  const closeNav = () => {
    const check = document.getElementById('menuSwitch') as HTMLInputElement;
    if (check) {
      check.click();
    }
  }

  return (
    <header className={props.isSticky ? 'sticky' + (props.shouldDisappear ? ' will-disappear' : '') : ''}>
      <input type="checkbox" id="menuSwitch" className="menuSwitch" />
      <div className="menu-container">
        <nav id="nav-main" style={{ minHeight: window.innerHeight }}>
          <div>
            <h1 className="xs-hide">Privilège Gym</h1>
            <p className="xs-hide">Nous sommes la référence à Beauport depuis 1983.</p>
            <h2 className="xs-hide">Gym 24 heures, 7 jours sur 7</h2>
            <h3>Nous contacter:</h3>
            <a href="tel:4186640400"><span role="img" aria-label="téléphone">☎</span> (418) 664-0400</a>
            <br/>
            <a href="mailto:info@privilegegym.ca"><span role="img" aria-label="téléphone">📨</span> info@privilegegym.ca</a>
            <br/>
            <p>2510 Boulevard Louis XIV, Beauport, Québec</p>
            <nav>
              <ul className="side-nav">
                <li>
                  <Link onClick={() => closeNav()} activeClass="active" to="abonnements" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                    <span className="btn">
                      abonnements
                    </span>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => closeNav()} activeClass="active" to="services" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                    <span className="btn">
                      services
                    </span>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => closeNav()} activeClass="active" to="privilege" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                    <span className="btn">
                      privilège gym
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </nav>
        <div className="menu">
          <div style={{ paddingLeft: 30 }}>
            {/* <a href="/" style={{ border: '2.7px solid #fff', padding: '0px 20px', borderRadius: '7px' }}>
              <h3 style={{ color: '#fff', textTransform: 'uppercase', fontWeight: 700, margin: '5px 0px', textAlign: 'center' }}>24<hr style={{ color: '#fff', margin: '2px 0px' }} />7</h3>
            </a> */}
            <Link className="link-24" activeClass="active" to="banner" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
              Ouvert 24 / 7
            </Link>
          </div>
          <div className="desktop-nav">
            <nav>
              <ul>
                <li>
                  <Link activeClass="active" to="abonnements" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                    abonnements
                  </Link>
                </li>
                <li>
                  <Link activeClass="active" to="services" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                    services
                  </Link>
                </li>
                <li>
                  <Link activeClass="active" to="privilege" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                    privilège gym
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            {/* Login section */}
            <label htmlFor="menuSwitch">
              <span className="burger">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </header>
  )
};