import React from 'react';
// import { Parallax } from 'react-scroll-parallax';

//@ts-ignore
import { Parallax } from 'react-parallax';

//@ts-ignore
import Bounce from 'react-reveal/Bounce';

export const Banner: React.FC = () => (
  <div className="banner" id="banner">
    <Parallax
      blur={0}
      bgImage={require('../assets/img/banner-bg.jpg')}
      bgImageAlt="Privilège Gym - Services"
      strength={500}
      className="par-container"
      bgClassName="par-img"
    >
      <div className="container">
        <div className="col">
          <Bounce right cascade>
            <div style={{ display: 'flex' }}>
              <img className="banner-img" src={require('../assets/img/logo.png')} alt="Logo Privilège Gym"/>
              <div>
                <h1 className="ultra">Le vrai gym 24 heures</h1>
                <h2 className="sideline" style={{ textAlign: 'center' }}>Depuis 1983</h2>
              </div>
            </div>
          </Bounce>
        </div>
      </div>
    </Parallax>
  </div>
)