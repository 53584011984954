import React from 'react';
// import { Parallax } from 'react-scroll-parallax';

//@ts-ignore
import { Parallax } from 'react-parallax';

//@ts-ignore
import Bounce from 'react-reveal/Bounce';

export const Service: React.FC = (props) => {
  return (
    <div className="services">
      <Parallax
        blur={0}
        bgImage={require('../assets/img/services-bg.jpg')}
        bgImageAlt="Privilège Gym - Services"
        strength={500}
        className="par-container"
      >
        <div className="container">
          <div className="col">
            <Bounce right cascade>
              <div>
                <h2>Votre santé nous tient à <span role="img" aria-label="<3">❤️</span></h2>
                <h3>Programmes sur mesure</h3>
                <h3>Plans alimentaires</h3>
                <h3>Ostéopathie</h3>
                <h3>Clinique de remise en forme</h3>
                <h3>Cours de groupe</h3>
                <p>Avec plus de 35 ans d'expérience, nous avons mis au point un gym qui optimise les résultats. Nous fournissons le savoir et l'équipement, vous contribuez avec votre énergie et vos réussites.</p>
              </div>
            </Bounce>
          </div>
        </div>
      </Parallax>
    </div>
  )
}