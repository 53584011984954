import React from 'react';

interface ICardProps {
  readonly action: Function;
  readonly info: string;
  readonly buttonText: string;
  readonly corner?: string;
  readonly isPromo?: boolean;
}

export const Card: React.FC<ICardProps> = (props) => (
  <div className="card">
    {props.corner !== undefined &&     
      <div className="corner">
        <h3>
        {props.corner}
        </h3>
      </div>
    }
    {props.isPromo === true &&     
      <div className="promo">
        <h3>
          promo
        </h3>
      </div>
    }
    <ul>
      {props.children}
    </ul>
    <button onClick={() => props.action()}>{props.buttonText}</button>
    <div className="info">
      {props.info}
    </div>
  </div>
)